import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function TermsNorthAmerica({
  children
}) {
  return <LegalDoc title="Terms of Service for Transaction Stream - North America" updated="14 Oct 22">
      {children}
    </LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Terms of Service set out the terms and conditions upon which you may use the Fidel API Service and any application or functionality Fidel API makes available through the Fidel API Service in North America.`}</p>
    <p>{`By signing an Order Form, you agree to and accept the Terms of Service and the Order Form.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Information about Fidel API`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Fidel API Service is provided by Enigmatic Smile (`}<strong parentName="li">{`“Fidel API”`}</strong>{`), a company incorporated and registered in England and Wales under company number 08869163 whose registered office is at Swift House Ground Floor, 18 Hoffmanns Way, Chelmsford, Essex, England, CM1 1GU. Fidel API’s VAT number is 247613206.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Interpretation`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In the Terms of Service, save where the context requires otherwise, the following words and expressions have the following meaning:`}
            <ol parentName="li">
              <li parentName="ol"><strong parentName="li">{`“Account”`}</strong>{` means the Customer’s account on the Fidel API Service;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Agreement”`}</strong>{` means the agreement between the Customer and Fidel API comprising the Order Form and the Terms of Service for the provision of the Fidel API Service (including the Data Processing Addendum);`}</li>
              <li parentName="ol"><strong parentName="li">{`“API”`}</strong>{` means Fidel API's application programming interface enabling the Customer to connect Users' Payment Cards with the Expense Management Platform;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Authorized User"`}</strong>{` means an employee, worker or other person who is authorized by the Customer to use the Fidel API Service on behalf of the Customer;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Authorization Notification”`}</strong>{` means the provision of a near-real time notification of Transaction Data to the Customer;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Business Day"`}</strong>{` means a day (other than a Saturday, Sunday or public holiday in England) when banks in London are open for business;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Card Network”`}</strong>{` means a payment processor that facilitates card payment transactions to provide Transaction Data to Fidel API (for example Visa or Mastercard);`}</li>
              <li parentName="ol"><strong parentName="li">{`“Cardholder Verification Fee”`}</strong>{` means the fee as set out in the Order Form payable each time a Customer attempts to connect or connects to the Verification SDK during the applicable monthly billing period;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Code”`}</strong>{` means the JavaScript or other source code provided by Fidel API to the Customer for incorporation into the Customer’s Expense Management Platform;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Commencement Date”`}</strong>{` means the date from which the Customer will receive the Fidel API Service, as set out in the Order Form;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Confidential Information”`}</strong>{` means information which is identified as confidential or proprietary by either party or by the nature of which is clearly confidential or proprietary;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Customer”`}</strong>{` means the person identified in the Order Form;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Customer Personal Data”`}</strong>{` has the meaning as set out in the Data Processing Addendum.`}</li>
              <li parentName="ol"><strong parentName="li">{`"Data Processing Addendum"`}</strong>{` means the data processing addendum attached hereto;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Documentation”`}</strong>{` means the description of the Fidel API Service functionality and related information available at: `}<a parentName="li" {...{
                  "href": "https://fidelapi.com/docs"
                }}>{`https://fidelapi.com/docs`}</a>{`;`}</li>
              <li parentName="ol"><strong parentName="li">{`“EMP Customer”`}</strong>{` means a client of the Customer who is provided with access to the Customer’s Expense Management Platform;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Expense Management Platform”`}</strong>{` means the expense management platform or application operated by the Customer in order to provide expense management services to EMP Customers;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Extended Term”`}</strong>{` means the period of time set out in the Order Form that the Agreement will automatically extend for following the end of the Initial Term or any Extended Term;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Fees”`}</strong>{` means any fees payable by Customer to Fidel API for the provision of the Fidel API Service pursuant to the Agreement;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Information Security Incident”`}</strong>{` means any actual or reasonably suspected: (a) unauthorized use of, or unauthorized access to, any of Customer’s computers or networks; (b) damage to, or inability to access, any of Customer’s computers or networks due to a malicious use, attack or exploit of such computers; (c) unauthorized access to, theft of, or loss of Confidential Information, specifically including but not limited to, personal data and Transaction Data; (d) unauthorized use of Confidential Information, specifically including, but not limited to, personal data and Transaction Data, for purposes of actual, reasonably suspected, or attempted theft, fraud, identity theft, or other misuse; (e) breach of, or transmission of malicious code or a Virus to, any of Customer’s computers or networks arising from, in whole or part, an act, error, or omission of Customer, or third parties acting on behalf of Customer; or (f) unauthorized disclosure of Confidential Information, specifically including, but not limited to, personal data and Transaction Data;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Initial Term”`}</strong>{` means the initial term of the Agreement, commencing on the Commencement Date, as set out in the Order Form;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Licensed Bank”`}</strong>{` means a bank that is licensed and authorized in the Territory;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Mastercard”`}</strong>{` means Mastercard Europe SA and its affiliated companies;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Merchant"`}</strong>{` means the person which operates the point-of-sale equipment relating to a Transaction;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Minimum Charge Fee”`}</strong>{` means the minimum monthly fee for use of the Fidel API Service, as set out in the Order Form;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Monthly Card Enrollment Fee”`}</strong>{` means the fee per card as set out in the Order Form payable on the total number of Payment Cards the Customer has enrolled on the Expense Management Platform at any point during the applicable monthly billing period;`}</li>
              <li parentName="ol"><strong parentName="li">{`"On Us Transaction"`}</strong>{` means a Transaction where the acquiring bank of the Merchant and issuing bank of the Payment Card used by a User to make a Transaction are the same entity, and the Transaction is settled outside of the Card Networks;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Onboarding Fee”`}</strong>{` means the one-off on-boarding fee payable by the Customer to Fidel API, as set out in the Order Form;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Order Form”`}</strong>{` means the signed (whether electronically or otherwise) order form which amongst other things, identifies the Customer and sets out the Fees;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Payment Card"`}</strong>{` means a consumer debit, credit, small business or commercial card issued by a Licensed Bank;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Qualifying Expenses”`}</strong>{` means any expenses incurred by a User using a Payment Card that is enrolled in the Expense Management Platform that a User has agreed are to be managed by the Expense Management Platform;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Qualifying Expenses Data”`}</strong>{` has the meaning set out in clause 5.2;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Settlement Notification”`}</strong>{` means the provision of non-real time Transaction Data to the Customer relating to cleared Transactions;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Term”`}</strong>{` means the period of time made up of the Initial Term plus any Extended Term;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Terms of Service”`}</strong>{` means these terms and conditions of service;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Territory”`}</strong>{` means the location(s) identified in the Order Form, and such other territories as may be agreed by the parties in writing from time to time;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Tier 1 Support”`}</strong>{` means first line user support on non-technical issues;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Tier 2 Support”`}</strong>{` means second line support on technical and urgent issues;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Transaction"`}</strong>{` means a point-of-sale purchase made by a User in the Territory with a Payment Card enrolled in the Expense Management Platform, excluding any On Us Transaction;`}</li>
              <li parentName="ol"><strong parentName="li">{`"Transaction Data"`}</strong>{` means data supplied via a Card Network relating to a Transaction including the following: last 4 digits of PAN, MCC, authorization code, Merchant location, card token, currency, authorization and settlement date and time, and amount paid;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Transaction Fee”`}</strong>{` means the fee payable per Transaction, as set out in the Order Form;`}</li>
              <li parentName="ol"><strong parentName="li">{`“User"`}</strong>{` means an EMP Customer whether an individual cardholder or corporate or employee or staff member of EMP Customer who has enrolled their Payment Card(s) with the Expense Management Platform;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Verification SDK”`}</strong>{` means the SDK made available by Fidel API which uses two-factor authentication to securely identify the User;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Virus”`}</strong>{` mean any thing or device (including any software, code, file or program) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any program or data, including the reliability of any program or data (whether by re-arranging, altering or erasing the program or data in whole or part or otherwise); or adversely affect the user experience, including worms, Trojan horses, viruses and other similar things or devices;`}</li>
              <li parentName="ol"><strong parentName="li">{`“Visa”`}</strong>{` means Visa U.S.A. Inc. and its affiliated companies; and "Website” means `}<a parentName="li" {...{
                  "href": "https://fidelapi.com/"
                }}>{`https://fidelapi.com/`}</a>{`.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Duration`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Agreement shall start on the Commencement Date in the Order Form and continue for the Term.`}</li>
          <li parentName="ol">{`The Initial Term of the Agreement shall automatically extend for an Extended Term at the end of the Initial Term, and at the end of each Extended Term thereafter, unless either party gives at least thirty (30) days’ written notice to the other party to terminate the Agreement at the end of the Initial Term or at the end of the then-current Extended Term, as the case may be, such notice to be given no later than thirty (30) days prior to the end of the Initial Term or then-current Extended Term (as applicable).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`User's consent to monitor payment activity`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer shall utilize the Fidel API iFrame as described in the Documentation, to obtain affirmative consent from Users to enable the Card Network to monitor activity on the User's Payment Card to:`}
            <ol parentName="li">
              <li parentName="ol">{`identify, track and record Transactions; and`}</li>
              <li parentName="ol">{`share such Transaction Data with the Customer, in order to enable Fidel API to provide the Fidel API Service.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Customer's restrictions on the use of Transaction Data`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In order to provide the Fidel API Service, Fidel API will provide the Customer with the Transaction Data.`}</li>
          <li parentName="ol">{`The Customer will ensure that it is able to identify and separate the Qualifying Expenses from the Transaction Data (the “Qualifying Expenses Data”) and shall only share the Qualifying Expenses Data with an EMP Customer and relevant Users to the extent necessary to provide its Expense Management Platform.`}</li>
          <li parentName="ol">{`The Customer agrees in respect of the Transaction Data received that it will:`}
            <ol parentName="li">
              <li parentName="ol">{`use the Transaction Data for the sole purpose of enabling the Customer to operate the Expense Management Platform (the “Permitted Use”);`}</li>
              <li parentName="ol">{`delete the Transaction Data as soon as reasonably practicable after having extracted the Qualifying Expenses Data and retain the Qualifying Expense Data only as long as necessary for the Permitted Use and in any event no longer than 1 year from the date of receipt of the Transaction Data;`}</li>
              <li parentName="ol">{`not share, transfer or disclose the Transaction Data to any third parties save for the EMP Customer or relevant Users as permitted by clause 5.2, or otherwise cause Transaction Data to be disclosed to any third parties without the prior written consent of Fidel API, except that the Customer may share Transaction Data with any subcontractors without the prior written consent of Fidel API, solely to the extent necessary for the purpose of performing the Customer’s rights and obligations under the Agreement, and subject to the remaining provisions of this clause. The Customer shall at all times remain liable for all of its obligations under the Agreement, and will be liable for the acts and omissions of any third party (including subcontractors) with which it shares, or to which it gives access to, Transaction Data as if they were the Customer’s own acts and omissions, and will ensure that all such third parties comply with any security obligations of the Customer and any restrictions regarding the use of Transaction Data, as set out in the Agreement;`}</li>
              <li parentName="ol">{`not use the Transaction Data (or any portion thereof), even if aggregated and anonymised, for any marketing purposes, customer profiling or analysis of customer behavior patterns, except as otherwise permitted under clause 5.3.1 or with the express permission of Fidel API and the Card Networks.`}</li>
              <li parentName="ol">{`not use the Transaction data for any credit risk determination or fraud purposes or, combine, commingle, or aggregate the Transaction Data with any data governed by the Fair Credit Reporting Act or Equal Credit Opportunity Act (such as credit bureau information, any data considered a “consumer report” or other data that scores a cardholder based on financial history) or any equivalent legislation in the Territory, except as otherwise approved by Fidel API and Card Networks; and`}</li>
              <li parentName="ol">{`not use the Transaction Data (or any portion thereof) to develop, derive, or create, directly or indirectly, a personal identification number, individual reference number, or any "customer data integration" system which Customer offers or makes available to third parties.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel API has the right to obtain equitable relief regarding the Customer’s use or threatened use of the Transaction Data contrary to the Permitted Use. Where the Card Network providing the Transaction Data is Mastercard, Mastercard shall be a third party beneficiary entitled to enforce this clause against the Customer.`}</li>
          <li parentName="ol">{`At the Customer’s expense, the Customer shall provide to Fidel API and to the Card Networks, and to each of their auditors and other advisers and representatives as Fidel API may from time to time designate in writing, (giving at least five (5) days' prior written notice) access at all reasonable times (and in the case of regulators, at any time required by such regulators) to any facility in which the Customer performs the Agreement, the Customer's employees, and data and records of the Customer, for the purpose of performing audits and inspections of the Customer in order to verify the Customer's compliance with the terms and conditions of the Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Access to the Fidel API Service`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API grants the Customer a non-exclusive, non-transferable, personal and non sub-licensable right in the Territory during the Term to use the Fidel API Service in connection with the operation of the Customer’s Expense Management Platform in the Territory.`}</li>
          <li parentName="ol">{`The Customer must treat any username and password to access the Fidel API Service or the Account as Confidential Information, and it must not disclose it to any third party.`}</li>
          <li parentName="ol">{`The Customer shall require that each Authorized User keeps secure and confidential their username and password for the Authorized User's use of the Fidel API Service via the Customer’s webpages or mobile application and shall not disclose such user name and password to any third party.`}</li>
          <li parentName="ol">{`Fidel API may suspend any Account, at any time and at Fidel API’s sole discretion, if the Customer has failed, in any material respect, to comply with any of the provisions of the Agreement.`}</li>
          <li parentName="ol">{`The Customer is responsible for maintaining the confidentiality of login details for its Account and any activities that occur under its Account including the activities of Authorized Users. Whenever applicable, Fidel API encourages the Customer to use “strong” passwords (passwords that use a combination of upper and lower case letters, numbers and symbols) with its Account. If the Customer has any concerns about the login details for its Account or thinks they have been misused, the Customer should promptly contact Fidel API at `}<a parentName="li" {...{
              "href": "mailto:cs@fidelapi.com"
            }}>{`cs@fidelapi.com`}</a>{`.`}</li>
          <li parentName="ol">{`The Customer must use commercially reasonable efforts to prevent any unauthorized access to, or use of, the Fidel API Service and, in the event of any such unauthorized access or use, as soon as reasonably practicable notify Fidel API.`}</li>
          <li parentName="ol">{`The Customer recognizes that Fidel API is always innovating and finding ways to improve the Fidel API Service with new features and services. The Customer acknowledges and agrees that the Fidel API Service may change from time to time. FIDEL API HEREBY DISCLAIMS ANY WARRANTY, REPRESENTATION OR OTHER COMMITMENT WITH RESPECT TO THE CONTINUITY OF ANY FUNCTIONALITY OF THE FIDEL API SERVICE.`}</li>
          <li parentName="ol">{`Fidel API undertakes that the Fidel API Service will be performed substantially in accordance with the Documentation and with reasonable skill and care.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Customer's obligations`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API authorizes the Customer to download the Code (including any updates to the Code that Fidel API may make available from time to time) from the Website for the sole purpose of integrating the Code into the Customer’s web pages or mobile applications in order to allow Users to connect Payment Cards with the Customer’s Expense Management Platform programs via the Fidel API Service.`}</li>
          <li parentName="ol">{`The Customer shall insert the Code into the Customer’s web pages or mobile applications in accordance with the reasonable directions given by Fidel API in writing (including email) from time to time.`}</li>
          <li parentName="ol">{`The Customer shall, on reasonable request from Fidel API, update the Code inserted into the Customer’s web pages as soon as reasonably practicable upon receiving notice from Fidel API to do the same.`}</li>
          <li parentName="ol">{`The Customer assumes sole responsibility for installation and integration of the Code with its IT systems including but not limited to the Customer's hardware, software, websites and apps.`}</li>
          <li parentName="ol">{`Fidel API permits the Customer to use the API (including updates to the API that Fidel API may make available from time to time) for the sole purpose of enabling the Customer to connect User’s Payment Cards with the Expense Management Platform.`}</li>
          <li parentName="ol">{`The Customer shall use the API in accordance with the reasonable directions given by Fidel API in writing (including email) from time to time.`}</li>
          <li parentName="ol">{`The Customer is encouraged to use the most current version of the API made available by Fidel API, following reasonable notice from Fidel API to do so. The Customer acknowledges that the API may only be backwards compatible for a period of two (2) years from the date of such notice.`}</li>
          <li parentName="ol">{`Fidel API may monitor the Customer’s use of the Fidel API Service to ensure quality, improve the Fidel API Service, and verify the Customer’s compliance with the Agreement.`}</li>
          <li parentName="ol">{`The Customer:`}
            <ol parentName="li">
              <li parentName="ol">{`must comply with all applicable laws and regulations with respect to its use of the Fidel API Service, the Transaction Data, and its activities under the Agreement;`}</li>
              <li parentName="ol">{`must only enroll Payment Cards issued by a Licensed Bank with the Expense Management Platform and take reasonable measures to verify the same. Fidel API may remove any Payment Cards that are not issued by a Licensed Bank from the Expense Management Platform (to the extent it becomes aware of this);`}</li>
              <li parentName="ol">{`must use the Fidel API Service and Transaction Data in accordance with the terms of the Agreement;`}</li>
              <li parentName="ol">{`must obtain and shall maintain all necessary licenses, consents, permits and permissions necessary for Fidel API to perform its obligations to the Customer under the terms of the Agreement;`}</li>
              <li parentName="ol">{`must ensure that its network and systems, including its internet browser used complies with the relevant specifications provided by Fidel API in writing (including email) from time to time;`}</li>
              <li parentName="ol">{`is solely responsible for procuring and maintaining its network connections and telecommunications links from its systems in order to access and use the Fidel API Service;`}</li>
              <li parentName="ol">{`shall not: (a) access, store, distribute or transmit any Virus through the Fidel API Service (b) use the Fidel API Service to access, store, distribute or transmit any material that is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive; (c) use the Fidel API Service in a manner that is illegal or causes damage or injury to any person or property; (d) use any automated system, including without limitation "robots," "spiders," "offline readers," to access the Fidel API Service in a manner that sends more request messages to the Fidel API Service than a human can reasonably produce in the same period of time by using a conventional online web browser, or conduct any denial of service attack on the Fidel API Service; (e) attempt to interfere with or compromise the Fidel API Service integrity or security. Fidel API reserves the right, without liability or prejudice to its other rights under the Agreement, to disable the Customer’s Account for material breaches of the provisions of this clause 7.9.7; and`}</li>
              <li parentName="ol">{`shall promptly notify Fidel API if any User unenrolls any of its Payment Cards from the Expense Management Platform.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Important note on intellectual property rights`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API is the owner of or the licensee of all intellectual property rights in the Fidel API Service. These works are protected by copyright and other laws and treaties around the world. All such rights are reserved.`}</li>
          <li parentName="ol">{`The Customer will not, when using the Fidel API Service:`}
            <ol parentName="li">
              <li parentName="ol">{`attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Fidel API Service in any form or media or by any means; or`}</li>
              <li parentName="ol">{`attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Fidel API Service; or`}</li>
              <li parentName="ol">{`access all or any part of the Fidel API Service in order to build a product or service which competes with the Fidel API Service or use or attempt to use the Fidel API Service to directly compete with Fidel API.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Publicity, case studies and feedback`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API may use the Customer’s name, logo and related trade marks in any of Fidel API’s publicity or marketing materials (whether in printed or electronic form) for the purpose of highlighting that the Customer uses the Fidel API Service and alongside any testimonials that the Customer has agreed to give. The Customer grants to Fidel API such rights as are necessary to use its name, logo, related trademarks and testimonial for the purposes of this clause 9.1.`}</li>
          <li parentName="ol">{`At the Customer’s written request, Fidel API shall cease use of the Customer’s name, logo, or related trade marks in Fidel API’s publicity and marketing materials, provided that Fidel API shall have no obligation to remove the Customer’s name, logo, and/or related trade marks from any of Fidel API’s publicity or marketing materials published (whether in printed or electronic form) prior to the date of Fidel API’s receipt of the Customer’s request.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Data protection`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API will process the Customer Personal Data in accordance with the Data Processing Addendum.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Information security`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer shall have in place appropriate technical and organizational measures to keep the Transaction Data secure, and to protect against unauthorized or unlawful disclosure of or access to, or accidental loss or destruction of, or damage to, any Transaction Data.`}</li>
          <li parentName="ol">{`The Customer must promptly notify Fidel API in writing upon becoming aware of any actual or suspected Information Security Incident.`}</li>
          <li parentName="ol">{`The Customer must promptly report any Information Security Incident in writing to Fidel API (and directly to Mastercard, where applicable), and in any event within twelve (12) hours of confirming such Information Security Incident.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Confidential Information`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Each party may be given access to Confidential Information from the other party in order to perform its obligations under the Agreement. A party's Confidential Information shall not be deemed to include information that:`}
            <ol parentName="li">
              <li parentName="ol">{`is or becomes publicly known other than through any act or omission of the receiving party;`}</li>
              <li parentName="ol">{`was in the other party's lawful possession before the disclosure;`}</li>
              <li parentName="ol">{`is lawfully disclosed to the receiving party by a third party without restriction on disclosure;`}</li>
              <li parentName="ol">{`is independently developed by the receiving party, which independent development can be shown by written evidence; or`}</li>
              <li parentName="ol">{`is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Each party shall hold the other's Confidential Information in confidence and, unless required by law, not make the other's Confidential Information available for use for any purpose other than as needed to perform the terms of the Agreement during the Term and for so long as the Confidential Information remains confidential.`}</li>
          <li parentName="ol">{`Each party shall take all reasonable steps to ensure that the other's Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of the terms of the Agreement.`}</li>
          <li parentName="ol">{`Each party shall limit dissemination of Confidential Information of the other party to: (a) persons within its own organisation; (b) to third parties who are bound by written confidentiality obligations consistent with this clause 12; and (c) all on a “need-to-know” basis.`}</li>
          <li parentName="ol">{`Each party shall use the same degree of care to protect the other’s Confidential Information as it uses to protect its own most highly confidential information, but in no circumstances less than reasonable care. In the event the receiving party receives a court order or other validly issued administrative or judicial process demanding the other’s Confidential Information, or to the extent required to be disclosed by any applicable laws or regulations by a party as a publicly traded company, the receiving party will be permitted to disclose the other’s Confidential Information to the minimum extent necessary to comply with such legal obligations. The receiving party will, to the extent not prohibited by law, give the disclosing party prompt written notice of any disclosure that, in the opinion of its counsel, appears to be required by law, so that the disclosing party, at its sole cost and expense, may assert any defenses to disclosure that may be available and seek an injunction or other protective relief.`}</li>
          <li parentName="ol">{`The parties agree that in the event of any breach of the confidentiality obligations described herein, the non-breaching party shall be entitled to seek immediate injunctive relief for such breach in addition to any other right or remedy that the non-breaching party may have at law or in equity.`}</li>
          <li parentName="ol">{`At the time of expiration or termination of the Agreement, as applicable, or upon the other party’s request, each party agrees to promptly return or destroy any Confidential Information provided by the other party in connection with the Agreement.`}</li>
          <li parentName="ol">{`Each party acknowledges and agrees that, as between the parties, Confidential Information disclosed by the disclosing party to the receiving party will at all times remain the property of the disclosing party. No license of any trade secrets, copyrights, or other rights is granted by any disclosure of Confidential Information under the Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Fees and payment`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer shall pay the Transaction Fee to Fidel API monthly in arrears.`}</li>
          <li parentName="ol">{`For the purpose of calculating the Transaction Fee, the provision of an Authorization Notification shall be considered one Transaction and the provision of a Settlement Notification shall be considered a second, separate Transaction. Each of the two Transactions will be subject to a separate Transaction Fee.`}</li>
          <li parentName="ol">{`The Customer shall pay the Monthly Card Enrollment Fee and Cardholder Verification Fee monthly in arrears.`}</li>
          <li parentName="ol">{`The Customer shall pay the Minimum Charge Fee for the first month of the Term, together with the Onboarding Fee, no later than two (2) working days from the Commencement Date. Thereafter, the Minimum Charge Fee for each month during the Term shall be payable monthly in advance.`}</li>
          <li parentName="ol">{`Fidel API shall invoice the Customer for the Fees at the end of each month during the Term. Customer shall pay any invoice within seven (7) Business Days from the receipt of the invoice.`}</li>
          <li parentName="ol">{`Unless alternative payment is agreed in the Order Form, the Customer will provide to Fidel API valid, up-to-date and complete credit or debit card details and it hereby authorizes Fidel API to bill such credit or debit card for the Fees in accordance with the Order Form.`}</li>
          <li parentName="ol">{`If Fidel API has not received full payment of an undisputed invoice within thirty (30) days after the due date, and without prejudice to any other rights and remedies available to Fidel API:`}
            <ol parentName="li">
              <li parentName="ol">{`Fidel API may, without liability to the Customer, suspend or temporarily disable all or part of its access to the Fidel API Service and Fidel API shall be under no obligation to provide any access to the Fidel API Service while the invoice(s) concerned remain unpaid, but shall reinstate such access at no further cost when the delinquent invoice(s) have been paid; and`}</li>
              <li parentName="ol">{`interest shall accrue each day on such due amounts at an annual rate equal to 3% over the then current base lending rate of Barclays Bank PLC at the date the relevant invoice was issued, commencing on the due date and continuing until fully paid, whether before or after judgment.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`All amounts and Fees stated or referred to in the Agreement:`}
            <ol parentName="li">
              <li parentName="ol">{`are payable in the currency specified in the Order Form or otherwise stipulated by Fidel API; and`}</li>
              <li parentName="ol">{`are exclusive of any taxes (including sales tax) unless otherwise expressly stated which shall be paid at the same time as payment of the Fees. Fidel API shall send the Customer a tax invoice if Fidel API is requested to do so.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Unless otherwise agreed between the parties in writing, Fidel API may increase any of the Fees at any time after the Initial Term upon giving at least sixty (60) days’ notice (the “Notice Period”) in writing to the Customer, such increase to take effect on expiry of such Notice Period. If the Customer is unhappy with the increase, the Customer may give notice to terminate the Agreement by providing at least thirty (30) days' written notice to Fidel API, and the Agreement will terminate at the end of the Notice Period. The Fees will not increase during the Notice Period.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Availability and support`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Where the Customer has paid for access to the Fidel API Service, Fidel API will use commercially reasonable efforts to make the Fidel API Service available with an uptime rate of 99.99% except for:`}
            <ol parentName="li">
              <li parentName="ol">{`planned maintenance for which twenty-four (24) hours’ notice will be given; or`}</li>
              <li parentName="ol">{`unscheduled maintenance during normal business hours (UK time) or otherwise, for which Fidel API will use reasonable efforts to give the Customer advance notice; or`}</li>
              <li parentName="ol">{`any downtime caused by or arising from the services (or any non-availability of the services) provided by the Card Networks.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`The Customer will provide Users with Tier 1 Support during normal business hours of the Customer. Fidel API will provide the Customer with Tier 2 Support to enable the Customer to provide Tier 1 Support to Users. The Customer may raise Tier 1 Support or Tier 2 Support tickets with Fidel API via email. Fidel API shall respond to email support queries no later than twenty-four (24) hours after receipt.`}</li>
          <li parentName="ol">{`Fidel API agrees that Transactions via any of the Card Networks will track and report no later than five (5) Business Days after they are made.`}</li>
          <li parentName="ol">{`The Customer acknowledges that the Fidel API Service is dependent on access to various third party data sources (including the Transaction Data). The Customer agrees that Fidel API is not responsible for the non-availability or interruption to the Fidel API Service caused by any such non-availability of any such third party data source unless such non-availability or interruption is caused by Fidel API acting negligently.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Suspension and termination`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`If the Customer fails to pay any sum due to Fidel API and such sum remains outstanding for a further thirty (30) days following notice requiring such sum to be paid, Fidel API may terminate the Agreement immediately by notice in writing and without any liability to the Customer.`}</li>
          <li parentName="ol">{`Fidel API may terminate the Agreement by written notice with immediate effect if the Customer infringes Fidel API’s intellectual property rights in the Fidel API Service.`}</li>
          <li parentName="ol">{`Either party may terminate the Agreement at any time on written notice to the other if:`}
            <ol parentName="li">
              <li parentName="ol">{`the other party is in material breach of any of the terms of the Agreement and either that breach is incapable of remedy, or the other party fails to remedy that breach within thirty (30) days after receiving written notice requiring it to remedy that breach;`}</li>
              <li parentName="ol">{`the other party is in breach of applicable law; or`}</li>
              <li parentName="ol">{`one of the following events of or relating to insolvency occurs:
(i) the other party commences a judicial or administrative proceeding under a law relating to insolvency for the purpose of reorganizing or liquidating the debtor or restructuring its debt;
(ii) anyone commences any such proceeding against the other party and either (A) the proceeding is not dismissed by midnight at the end of the sixtieth (60th) day after commencement or (B) any court before which the proceeding is pending issues an order approving the case; a receiver, trustee, administrator, or liquidator (however each is referred to) is appointed or authorized, by law or under a contract, to take charge of property of the party for the purpose of enforcing a lien against that property, or for the purpose of general administration of that property for the benefit of the party’s creditors;
(iii) the other party makes a general assignment for the benefit of creditors; or
(iv) the other party generally fails to pay its debts as they become due (unless those debts are subject to a good-faith dispute as to liability or amount) or acknowledges in writing that it is unable to do so.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Upon termination of the Agreement for any reason all licenses granted under the Agreement shall immediately terminate and the Customer's right to access and use the Fidel API Service will end.`}</li>
          <li parentName="ol">{`If the Agreement is terminated by Fidel API in accordance with clause 15.1, 15.2 or 15.3, the Customer shall pay Fidel API any shortfall between the total aggregate Minimum Charge Fee for the duration of the Term and any Fees already paid to Fidel API at the date of termination together with all other amounts payable to Fidel API, within thirty (30) days of the date of termination of the Agreement.`}</li>
          <li parentName="ol">{`The accrued rights of the parties as at termination, or the continuation after termination of any provision expressly stated to survive or implicitly surviving termination shall not be affected or prejudiced.`}</li>
          <li parentName="ol">{`In the event that a Card Network is unwilling or unable to provide Transaction Data to Fidel API or the Customer, Fidel API shall continue to provide the Fidel API Service only in respect of the remaining Card Networks. Fidel API may terminate the Agreement by written notice with immediate effect in the event that all Card Networks are unwilling or unable to provide the Transaction Data to Fidel API or the Customer. If this clause is exercised, Fidel API agrees to provide the Customer with written proof that the relevant Card Network(s) is unwilling or unable to provide the Transaction Data.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Limited warranty and disclaimer`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`EXCEPT AS EXPRESSLY PROVIDED IN THE AGREEMENT AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE FIDEL API SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND FIDEL API MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, OR FOR ACTS OR OMISSIONS RELATED TO THIRD PARTY PROVIDERS, THIRD PARTY SOFTWARE (INCLUDING OPEN SOURCE SOFTWARE) OR THIRD PARTY MATERIALS. FIDEL API FURTHER DISCLAIMS ANY WARRANTY THAT THE OPERATION OF THE FIDEL API SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE.`}</li>
          <li parentName="ol">{`Except as expressly and specifically provided for in the Agreement:`}
            <ol parentName="li">
              <li parentName="ol">{`the Customer assumes sole responsibility for: (a) installation and integration of the Fidel API Service with its IT systems including but not limited to the Customer's hardware, software, websites and apps; and (b) any results obtained from the use of the Fidel API Service and for any conclusions drawn or taken from such use and it relies on the results obtained from the Fidel API Service at its own risk;`}</li>
              <li parentName="ol">{`all representations, warranties, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by law, excluded from the Agreement; and`}</li>
              <li parentName="ol">{`Fidel API will not be responsible for any interruptions, delays, failures or non-availability affecting the Fidel API Service or the performance of the Fidel API Service which are caused by third party services or errors or bugs in software, hardware or the internet on which Fidel API relies to provide the Fidel API Service and the Customer acknowledges that Fidel API does not control such third party services and that such errors and bugs are inherent in the use of such software, hardware and the Internet.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`SOME STATES DO NOT ALLOW EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Liability`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`SUBJECT TO CLAUSE 17.2, NEITHER PARTY WILL BE LIABLE TO THE OTHER WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR BREACH OF STATUTORY DUTY), MISREPRESENTATION, RESTITUTION OR OTHERWISE FOR ANY: (A) LOSS OF INCOME OR REVENUE; (B) LOSS OF BUSINESS; (C) LOSS OF PROFITS; (D) LOSS OF ANTICIPATED SAVINGS; (E) LOSS OF DATA (OTHER THAN LOSS OF DATA CAUSED BY A BREACH OF THE AGREEMENT); OR (F) ANY INDIRECT, CONSEQUENTIAL OR SPECIAL DAMAGES, COSTS OR EXPENSES.`}</li>
          <li parentName="ol">{`NOTHING IN THE AGREEMENT EXCLUDES OR LIMITS EITHER PARTY’S LIABILITY FOR GROSS NEGLIGENCE OR FOR FRAUD OR FRAUDULENT MISREPRESENTATION OR FOR ANY INDEMNITY GIVEN BY EITHER PARTY IN ACCORDANCE WITH CLAUSE 18.`}</li>
          <li parentName="ol">{`SUBJECT TO CLAUSE 17.2 AND OTHER THAN AS EXPRESSLY STATED OTHERWISE IN THE AGREEMENT, EACH PARTY’S TOTAL LIABILITY IN CONTRACT, TORT (INCLUDING NEGLIGENCE OR BREACH OF STATUTORY DUTY), MISREPRESENTATION, RESTITUTION OR OTHERWISE ARISING IN CONNECTION WITH THE PERFORMANCE OR CONTEMPLATED PERFORMANCE OF THE AGREEMENT SHALL IN ALL CIRCUMSTANCES BE LIMITED TO AN AMOUNT EQUAL TO THE FEES PAID OR PAYABLE BY THE CUSTOMER IN THE FIRST TWELVE (12) MONTHS OF THE TERM.`}</li>
          <li parentName="ol">{`SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THE AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THE AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Indemnity`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer shall indemnify and defend Fidel API, the Card Networks, and each of their officers, directors, employees, agents and contractors from and against any and all losses, damages, claims, liabilities or expenses (including reasonable costs and attorneys’ fees) arising out of any claim brought by a third party relating to the Customer's use of the Fidel API Service (except to the extent caused by Fidel API's negligence and other than as set out in clause 18.2), provided that Fidel API:`}
            <ol parentName="li">
              <li parentName="ol">{`gives the Customer prompt notice of any such claim;`}</li>
              <li parentName="ol">{`provides reasonable cooperation to the Customer in the defense and settlement of such claim, at the Customer's expense; and`}</li>
              <li parentName="ol">{`gives the Customer sole authority to defend or settle the claim.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel API shall indemnify and defend the Customer, its agents and employees against any and all losses, damages, claims, liabilities or expenses (including reasonable lawyers' fees) arising out of a claim brought by a third party alleging that the use of the Fidel API Service in accordance with the Agreement infringes the intellectual property rights of such third party provided that the Customer:`}
            <ol parentName="li">
              <li parentName="ol">{`gives Fidel API prompt notice of any such claim;`}</li>
              <li parentName="ol">{`provides reasonable cooperation to Fidel API in the defense and settlement of such claim, at Fidel API's expense; and`}</li>
              <li parentName="ol">{`gives Fidel API sole authority to defend or settle the claim.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`In no event shall Fidel API, its employees, agents and sub-contractors be liable to the Customer to the extent that the alleged infringement is based on:`}
            <ol parentName="li">
              <li parentName="ol">{`a modification of the Fidel API Service by anyone other than Fidel API or anyone acting on behalf of Fidel API;`}</li>
              <li parentName="ol">{`the Customer's use of the Fidel API Service in a manner contrary to the Agreement; or`}</li>
              <li parentName="ol">{`the Customer's use of the Fidel API Service after notice of the alleged or actual infringement from Fidel API or any appropriate authority has been given to the Customer.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Written communications`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Applicable laws may require that some of the information or communications Fidel API sends to the Customer should be in writing. When using the Fidel API Service, the Customer accepts that communication with Fidel API will be mainly electronic. Fidel API will contact the Customer by e-mail or provide the Customer with information by posting notices on the Fidel API Service. For contractual purposes, the Customer agrees to this electronic means of communication and the Customer acknowledges and agrees that all contracts, notices, information and other communications that Fidel API provides to the Customer electronically comply with any legal requirement that such communications be in writing.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Notices`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`All notices given by the Customer to Fidel API must be given to `}<a parentName="li" {...{
              "href": "mailto:cs@fidelapi.com."
            }}>{`cs@fidelapi.com.`}</a>{` Fidel API may give notice to the Customer at either the e-mail or postal address the Customer provides to Fidel API, or any other way Fidel API deems appropriate. Notice will be deemed received and properly served immediately when posted on the Fidel API Service or twenty-four (24) hours after an e-mail is sent or three (3) days after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an e-mail that such e-mail was sent to the specified e-mail address of the addressee.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Transfer of rights and obligations`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Customer may not transfer, assign, charge or otherwise deal in the Agreement, or any of its rights or obligations arising under the Agreement, without Fidel API’s prior written consent.`}</li>
          <li parentName="ol">{`Fidel API may transfer, assign, charge, or otherwise deal in the Agreement, or any of its rights or obligations arising under the Agreement, in Fidel API’s sole discretion without the Customer’s prior written consent. If the Customer sells all or a substantial part of its business or materially changes the nature of its business, or by any means causes or permits a new entity to own a substantial part of its business, Customer agrees to notify Fidel API in advance.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Changes to the Agreement`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Fidel API may amend the Terms of Service from time to time. Every time the Customer uses the Fidel API Service, the Customer acknowledges that it is responsible for checking the Terms of Service to ensure it understands the Terms of Service that apply at any time. Any changes to the Terms of Service in the future will be posted on this page and, where appropriate, notified to the Customer by e-mail.`}</li>
          <li parentName="ol">{`Subject to clause 13.11, no variation of the Order Form shall be effective unless it is in writing and agreed by the parties (or their authorized representatives).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Events outside a party's control`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`No party shall be liable to the other for any delay or non-performance of its obligations under the Agreement arising from any cause beyond its control including, without limitation, any of the following: telecommunications failure, internet failure, act of God, governmental act, war, fire, flood, explosion or civil commotion.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Waiver`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`No forbearance or delay by either party in enforcing its rights shall prejudice or restrict the rights of that party, and no waiver of any such rights or of any breach of any contractual terms shall be deemed to be a waiver of any other right or of any later breach.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Severability`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`If any provision of the Agreement is judged to be illegal or unenforceable, the continuation in full force and effect of the remainder of the provisions shall not be prejudiced.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Third party rights`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Except as otherwise expressly provided in the Agreement, no provisions of the Agreement are intended nor shall be interpreted to provide or create any third-party beneficiary rights or any other rights of any kind in any other party.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Law and jurisdiction`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Agreement may not be amended without the prior written consent of both parties, and shall be governed by and interpreted in accordance with the laws of the state of New York (without reference to conflict of law principles) in the city of New York and county of New York.  The Customer hereby consents to jurisdiction in New York, New York for all claims arising out of or relating to the Agreement.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`Counterparts`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`The Agreement may be executed in counterparts, each of which shall be deemed an original and all of which together shall constitute one and the same instrument. The Agreement may be executed by facsimile or .pdf signature, either of which shall constitute an original for all purposes.`}</li>
        </ol>
      </li>
    </ol>
    <h3><strong parentName="h3">{`DATA PROCESSING ADDENDUM`}</strong></h3>
    <ol>
      <li parentName="ol"><strong parentName="li">{`BACKGROUND`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`In accordance with clause 10 of the Agreement, this Data Processing Addendum (`}<strong parentName="li">{`"DPA"`}</strong>{`) sets out the basis on which Fidel API uses the Customer Personal Data (as defined below) for the purposes of providing the Fidel API Service.`}</li>
          <li parentName="ol">{`In the event of a conflict between any of the provisions of this DPA and the remaining provisions of the Agreement, the provisions of this DPA shall prevail.`}</li>
          <li parentName="ol">{`Both parties will comply with all applicable requirements of the Data Protection Laws (as defined below). This DPA is in addition to, and does not relieve, remove or replace, a party's obligations under the Data Protection Laws.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DEFINITIONS`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Unless otherwise set out below, each capitalized term in this DPA shall have the meaning set out in clause 2 of the Agreement, and the following capitalized terms used in this DPA shall be defined as follows:`}
            <ol type="a">
     <li>
         <strong>"Customer Personal Data"</strong> means the <strong>“personal data”</strong> or <strong>“personal information”</strong> (as defined in the Data Protection Laws) of Users supplied by the Customer for the purposes of Fidel API providing the Fidel API Service;
     </li>
     <li>
      <strong>“Data Protection Laws”</strong> means all laws relating to the use, protection and privacy of Customer Personal Data (including, without limitation, the privacy of electronic communications) which are from time to time applicable to the Customer, Fidel API or the Fidel API Service;
     </li>
     <li>
      <strong>"Security Incident"</strong> means any accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, any Customer Personal Data; and
     </li>
     <li>
      <strong>"Sub-processor"</strong> means any processor engaged by Fidel API that agrees to receive from Fidel API any Customer Personal Data.
     </li>
 </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DATA PROCESSING`}</strong>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Instructions for Data processing.`}</strong>
            <ol type="a">
    <li>
        Fidel API will only process Customer Personal Data in accordance with: (i) the Agreement, to the extent necessary to provide the Fidel API Service; and (ii) the Customer's written instructions; unless processing is required by applicable laws to which Fidel API is subject, in which case Fidel API shall, to the extent permitted by such law, inform the Customer of that legal requirement before processing that Customer Personal Data.
    </li>
    <li>
        The Agreement (subject to any changes to the Fidel API Service agreed between the parties), including this DPA, shall be the Customer's complete and final instructions to Fidel API in relation to the processing of Customer Personal Data.
    </li>
    <li>
        Processing outside the scope of this DPA or the Agreement will require prior written agreement between the Customer and Fidel API on additional instructions for processing.
    </li>
            </ol>
          </li>
          <li parentName="ol"><strong parentName="li">{`Required consents and disclosures.`}</strong>{` Where required by applicable Data Protection Laws, the Customer will ensure that it has obtained or will obtain all necessary consents, and has provided appropriate disclosures and notices, for the processing of Customer Personal Data by Fidel API and the Card Networks in accordance with the Agreement, including consent from Users to enable the Card Networks to collect, process and share Customer Personal Data relating to the Users for the purposes set out in the Agreement (including this DPA).`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`TRANSFER OF PERSONAL DATA`}</strong>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Authorized Sub-processors.`}</strong>
            <ol type="a">
    <li>
        The Customer agrees that Fidel API may use Amazon Web Services, Inc. as a Sub-processor to process Customer Personal Data.
    </li>
    <li>
        The Customer agrees that Fidel API may use subcontractors to fulfil its contractual obligations under the Agreement. Fidel API shall notify the Customer from time to time of the identity of any Sub-processor it engages. If the Customer (acting reasonably) does not approve of a new Sub-processor, then the Customer may request that Fidel API moves the Customer Personal Data to another Sub-processor and Fidel API shall, within a reasonable time following receipt of such request, use all reasonable efforts to ensure that the Sub-processor does not process any of the Customer Personal Data.
    </li>
    <li>
        Fidel API shall use reasonable efforts to ensure that it enters into a written agreement with any Sub-processors which imposes materially the same obligations on the Sub-processor with regards to their processing of Customer Personal Data as are imposed on Fidel API under this DPA.
    </li>
            </ol>
          </li>
          <li parentName="ol"><strong parentName="li">{`Liability of Sub-processors.`}</strong>{` Fidel API shall at all times remain responsible for compliance with its obligations under the DPA and will be liable to the Customer for the acts and omissions of any Sub-processor approved by the Customer as if they were the acts and omissions of Fidel API.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DATA SECURITY, AUDITS AND SECURITY NOTIFICATIONS`}</strong>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Fidel API Security Obligations.`}</strong>{` Fidel API shall implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including the measures set out in ANNEX 1.`}</li>
          <li parentName="ol"><strong parentName="li">{`Security Audits.`}</strong>{` The Customer may, upon reasonable notice, audit (by itself or using independent third party auditors) Fidel API’s compliance with the security measures set out in this DPA (including the technical and organizational measures as set out in ANNEX 1), including by conducting audits of Fidel API’s data processing facilities. Upon request by the Customer, Fidel API shall make available all information reasonably necessary to demonstrate compliance with this DPA.`}</li>
          <li parentName="ol"><strong parentName="li">{`Security Incident Notification.`}</strong>{` If Fidel API or any Sub-processor becomes aware of a Security Incident, Fidel API will (a) notify the Customer of the Security Incident within seventy-two (72) hours, (b) investigate the Security Incident and provide such reasonable assistance to the Customer (and any law enforcement or regulatory official) as required to investigate the Security Incident, and (c) take steps to remedy any non-compliance with this DPA.`}</li>
          <li parentName="ol"><strong parentName="li">{`Fidel API Employees and Personnel.`}</strong>{` Fidel API shall treat the Customer Personal Data as the Confidential Information of the Customer, and shall ensure that:`}
            <ol type="a">
    <li>
        access to Customer Personal Data is limited to those employees or other personnel who have a business need to have access to such Customer Personal Data; and
    </li>
    <li>
        any employees or other personnel have agreed in writing to protect the confidentiality and security of Customer Personal Data.
    </li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`INDIVIDUALS’ RIGHTS`}</strong>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Requests from individuals.`}</strong>
            <ol type="a">
    <li>
        Save as required (or where prohibited) under applicable law, Fidel API shall notify the Customer of any request received by Fidel API or any Sub-processor from an individual to exercise their rights in respect of their personal data included in the Customer Personal Data under Data Protection Laws (“Individual Requests”), and shall not respond to any such request.
    </li>
    <li>
        Fidel API shall use reasonable efforts to assist the Customer to fulfil the Customer’s obligation to respond to Individual Requests.
    </li>
            </ol>
          </li>
          <li parentName="ol"><strong parentName="li">{`Government Disclosure.`}</strong>{` Fidel API shall notify the Customer of any request for the disclosure of Customer Personal Data by a governmental or regulatory body or law enforcement authority (including any data protection supervisory authority), unless otherwise prohibited by law or a legally binding order of such body or agency.`}</li>
        </ol>
      </li>
      <li parentName="ol"><strong parentName="li">{`DELETION OF DATA`}</strong>
        <ol parentName="li">
          <li parentName="ol">{`Subject to paragraphs 7.2 and 7.3 below, Fidel API shall, within ninety (90) days of the date of termination of the Agreement:`}
            <ol type="a">
    <li>
        return a complete copy of all Customer Personal Data by secure file transfer in such a format as notified by the Customer to Fidel API; and
    </li>
    <li>
        delete and use all reasonable efforts to procure the deletion of all other copies of Customer Personal Data processed by Fidel API or any Sub-processors.
    </li>
            </ol>
          </li>
          <li parentName="ol">{`Subject to paragraph 7.3 below, the Customer may in its absolute discretion notify Fidel API in writing within thirty (30) days of the date of termination of the Agreement to require Fidel API to delete and procure the deletion of all copies of Customer Personal Data processed by Fidel API. Fidel API shall, within ninety (90) days of the date of termination of the Agreement:`}
            <ol type="a">
    <li>
        comply with any such written request; and
    </li>
    <li>
        use all reasonable efforts to procure that its Sub-processors delete all Customer Personal Data processed by such Sub-processors, and, where this paragraph 7.2 applies, Fidel API shall not be required to provide a copy of the Customer Personal Data to the Customer.
    </li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel API and its Sub-processors may retain Customer Personal Data to the extent required by applicable laws and only to the extent and for such period as required by applicable laws and always provided that Fidel API shall ensure the confidentiality of all such Customer Personal Data and shall ensure that such Customer Personal Data is only processed as necessary for the purpose(s) specified in the applicable laws requiring its storage and for no other purpose.`}</li>
        </ol>
      </li>
    </ol>
    <h2><strong parentName="h2">{`Annex 1`}</strong></h2>
    <h3>{`TECHNICAL AND ORGANIZATIONAL SECURITY MEASURES`}</h3>
    <ol>
      <li parentName="ol">{`Fidel API maintains internal policies and procedures, or procures that its Sub-processors do so, which are designed to:`}
        <ol type="a">
    <li>
        secure any Customer Personal Data processed by Fidel API against accidental or unlawful loss, access or disclosure;
    </li>
    <li>
        identify reasonably foreseeable and internal risks to security and unauthorized access to the Customer Personal Data processed by Fidel API;
    </li>
    <li>
        minimize security risks, including through risk assessment and regular testing
    </li>
        </ol>
      </li>
      <li parentName="ol">{`Fidel API will, and will use reasonable efforts to procure that its Sub-processors do, conduct periodic reviews of the security of its network and the adequacy of its information security program as measured against industry security standards and its policies and procedures.`}</li>
      <li parentName="ol">{`Fidel API will, and will use reasonable efforts to procure that its Sub-processors periodically do, evaluate the security of its network and associated services to determine whether additional or different security measures are required to respond to new security risks or findings generated by the periodic reviews.`}</li>
      <li parentName="ol">{`Fidel API is Level 1 PCI compliant.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      